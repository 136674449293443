import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AwesomeIcon, Button, ColouredSection } from '@/components/ui'

const features = [
  {
    name: 'Weeks not months',
    description:
      'Create and launch an NFT in minutes with our listing builder.',
    icon: <AwesomeIcon icon={solid('timer')} className="h-6 w-6 text-white" />,
  },
  {
    name: 'You set the terms',
    description:
      'Set your own entry requirements, allocation, price & royalty rates.',
    icon: (
      <AwesomeIcon
        icon={solid('file-signature')}
        className="h-6 w-6 text-white"
      />
    ),
  },
  {
    name: 'Grow your userbase',
    description:
      'Unique NFT features help you grow your audience as well as your bank balance.',
    icon: (
      <AwesomeIcon icon={solid('seedling')} className="h-6 w-6 text-white" />
    ),
  },
  {
    name: 'Unlock recurring revenue',
    description: 'Receive lifetime royalties when an NFT is re-sold.',
    icon: (
      <AwesomeIcon
        icon={solid('envelope-open-dollar')}
        className="h-6 w-6 text-white"
      />
    ),
  },
]

const SellerFeatures = () => {
  return (
    <ColouredSection
      theme="gradient-indigo"
      title="Create and sell NFT's in your businesss"
    >
      <p className="mt-4 max-w-3xl text-lg text-indigo-200">
        Floor3 helps you raise funding and grow your userbase at the same time.
      </p>
      <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
        {features.map((feature) => (
          <div key={feature.name}>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                {feature.icon}
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">{feature.name}</h3>
              <p className="mt-2 text-base text-indigo-200">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Button
        text={
          <>
            Create an NFT
            <AwesomeIcon
              icon={solid('angle-right')}
              className="h-4 w-4 ml-3 text-indigo-200"
            />
          </>
        }
        size="LG"
        className="mt-12"
      />
    </ColouredSection>
  )
}
export { SellerFeatures }
