import { useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { solid, duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { PageWrapper } from '@/components/app'
import { ListingList } from '@/components/models/listing/page'
import { Hero, SellerFeatures, InvestorFeatures } from '@/components/marketing'
import { ColouredSection, Button } from '@/components/ui'
import { ListingModel } from '@/models'

const Home = () => {
  const [listings, setListings] = useState<ListingModel[]>([])

  const init = async () => {
    const { items } = await ListingModel.getPublishedListings(
      {},
      '',
      'popular',
      {
        page: 1,
        perPage: 8,
      }
    )

    setListings(items)
  }

  useAsyncEffect(async () => {
    await init()
  }, [])

  return (
    <>
      <Hero />

      <InvestorFeatures />

      <ColouredSection
        theme="gray-50"
        title="Trending NFT's"
        titleIcon={duotone('arrow-trend-up')}
        titleRight={
          <>
            <Button
              text="Browse All"
              icon={solid('right-long')}
              iconRight
              style="SECONDARY"
              size="SM"
              className="ml-2"
              href="/explore"
            />
          </>
        }
      >
        <ListingList listings={listings} />
      </ColouredSection>

      <SellerFeatures />
    </>
  )
}

export default PageWrapper(Home, { pageTitle: '', layout: { mode: 'PUBLIC' } })
