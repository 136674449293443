import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AwesomeIcon, Button, ColouredSection } from '@/components/ui'

const features = [
  {
    name: 'Exclusive Discounts',
    description:
      'Discounts can range from 10% off every purchase to lifetime free access to the companies software.',
    icon: <AwesomeIcon icon={solid('tags')} className="h-6 w-6 text-white" />,
  },
  {
    name: 'Unlock Rewards',
    description:
      'Own an NFT in a company, and join an exclusive club. Access a private community & unlock member benefits.',
    icon: <AwesomeIcon icon={solid('gift')} className="h-6 w-6 text-white" />,
  },
  {
    name: 'Dividend Style Payouts',
    description:
      'Receive a percentage of quarterly or annual profits, direct into your wallet.',
    icon: (
      <AwesomeIcon
        icon={solid('envelope-open-dollar')}
        className="h-6 w-6 text-white"
      />
    ),
  },
  {
    name: 'Affiliate Commissions',
    description:
      'Help grow the company by sending them referrals. Create a passive income & increase the NFT resale value.',
    icon: <AwesomeIcon icon={solid('users')} className="h-6 w-6 text-white" />,
    // Send referrals to the company, to help them grow.
    // Each NFT can act as an affiliate for the company you've invested in.
    //  Commissions are paid directly to your wallet
  },
]

const InvestorFeatures = () => {
  return (
    <ColouredSection
      theme="gradient-purple-indigo"
      title="Invest in companies, big and small"
    >
      <p className="mt-4 max-w-3xl text-lg text-purple-200">
        Unlock exclusive benefits and even receive dividend style payouts. Hold
        your NFT or sell when it's raised in value - it's up to you.
      </p>
      <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
        {features.map((feature) => (
          <div key={feature.name}>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                {feature.icon}
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-white">{feature.name}</h3>
              <p className="mt-2 text-base text-indigo-200">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Button
        text={
          <>
            Explore NFT's
            <AwesomeIcon
              icon={solid('angle-right')}
              className="h-4 w-4 ml-3 text-indigo-200"
            />
          </>
        }
        href="/explore"
        size="LG"
        className="mt-12"
      />
    </ColouredSection>
  )
}
export { InvestorFeatures }
