import Link from 'next/link'

const Hero = () => {
  return (
    <div className="pb-8 px-4 sm:px-6 lg:px-8 sm:pb-12 lg:pb-12">
      <div className="overflow-hidden lg:relative lg:py-24">
        <div className="mx-auto max-w-md psm:max-w-3xl lg:max-w-7xl lg:grid lg:grid-cols-2">
          <div>
            <div>
              <div>
                <Link href="/floor3/founding-member">
                  <a className="inline-flex space-x-4">
                    <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                      We're raising!
                    </span>
                    <span className="inline-flex items-center text-sm font-medium text-indigo-600 space-x-1">
                      <span>Own a founding member NFT</span>
                    </span>
                  </a>
                </Link>
              </div>
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Buy NFT’s in the companies you{' '}
                  <span className="underline decoration-sky-500">love</span>
                </h1>
                <p className="mt-6 text-xl text-gray-500">
                  Invest in companies, big and small. Join them on their journey
                  as they scale - unlocking exclusive rewards, discounts,
                  commissions and payouts along the way.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="sm:relative lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="relative sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full">
              <img
                src="/img/illustrations/nft-cards.png"
                alt="Floor3 NFT Cards"
                className="w-full relative top-4 lg:w-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export { Hero }
